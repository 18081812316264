import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/work/hiit-timer",
  "title": "HIIT Timer",
  "description": "New framework test to create a countdown of exercises and rest periods during a HIIT workout.",
  "workTags": ["UX/UI Design"],
  "tags": ["Website Design", "Development", "React"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/preview-images/hiit-exercise-mockup-long_2x_xvdgyx.png",
    "originalHeight": 1248,
    "originalWidth": 2128,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "work_jumbo_title": "An idea to energize myself that could be helpful for others",
  "laptop_image": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639095201/portfolio/laptop-images/hiit-laptop-mock_ufwn98.png",
  "role": "Designer/Developer",
  "deliverable": "Hosted on Heroku",
  "url": "https://hiit-timer-tp.herokuapp.com/",
  "github": "https://github.com/vpugh/hiit-timer-react",
  "client": "Self",
  "draft": false,
  "type": "work",
  "order": 3,
  "date": "2018-01-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Challenge`}</h3>
    <p>{`Looking to make something useful I was trying to get into controlled exercies and ran into HIIT training. I thought it would be cool to make my own HIIT timer that would countdown the seconds and tell me what exercise to do. You can create your own exercise list.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639366598/portfolio/work-images/hiit-exercise-timer/HIIT-Timer-Moving_zv1lay.gif",
        "alt": "alt"
      }}></img></p>
    <h4>{`Speed up timer in action`}</h4>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639366603/portfolio/work-images/hiit-exercise-timer/hiit-timer-tp.herokuapp_2_2x_qe3m10.png",
        "alt": "alt"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639366604/portfolio/work-images/hiit-exercise-timer/hiit-timer-tp.herokuapp_1_2x_ebgdxi.png",
        "alt": "alt"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      